<template>
  <div id="setting-register-sell-page" class="container-register-sell-page">
    <div class="logo-landing-page">
      <img
          src="@/assets/images/icon/logo-s.svg"
          width="100px"
          alt="logo"
      />
      <img
          src="@/assets/images/icon/sms-2pro-logo.svg"
          width="250px"
          alt="text_logo"
      />
    </div>
    <register @submitPhase="setPhase" v-if="submitPhase === 'registerPhase'"/>
    <OTP @submitPhase="setPhase" v-else-if="submitPhase === 'otpPhase'"/>
    <Footer landing-page/>
  </div>
</template>

<script>
import SetPassword from "@/views/sellPage/Register/setPassword.vue";
import Register from "@/views/sellPage/Register/Register.vue";
import OTP from "@/views/sellPage/Register/Otp.vue";
import Footer from "@/layouts/full-layout/footer/Footer.vue";

export default {
  name: "MainSellRegister",
  components: {
    Footer,
    Register,
    SetPassword,
    OTP
  },
  data: () => ({
    package_id: null,
    premium_url: null,
    submitPhase: sessionStorage.getItem('setPhase') ? sessionStorage.getItem('setPhase') : 'registerPhase',
  }),
  beforeMount() {
    const package_id = this.$route.query?.package_id ? this.$route.query?.package_id : null
    if (_.isNil(package_id)) {
      this.$router.push({path: '/Sell/Package-List'})
    }
  },
  mounted() {
    this.package_id = this.$route.query?.package_id ? this.$route.query?.package_id : null
    this.premium_url = this.$route.query?.premium_url ? this.$route.query?.premium_url : false
    this.submitPhase = sessionStorage.getItem('setPhase') ? sessionStorage.getItem('setPhase') : 'registerPhase'
    sessionStorage.setItem('package_id', this.package_id)
    sessionStorage.setItem('premium_url', this.premium_url)
  },
  methods: {
    setPhase(param) {
      if (param) {
        sessionStorage.setItem('setPhase', param)
        this.submitPhase = sessionStorage.getItem('setPhase') ? sessionStorage.getItem('setPhase') : 'registerPhase'
      }
    }
  }
}
</script>
<style lang="scss">
#nprogress .bar {
  background: #19CBAC !important;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px !important;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #19CBAC, 0 0 5px #19CBAC !important;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#setting-register-sell-page.container-register-sell-page {
  padding: 58px 16%;
  @media (max-width: 576px) {
    padding: 48px 5%;
  }
}

#setting-register-sell-page {

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  .landing-text-grey {
    color: #767676;
  }

  .btn-layout {
    .primary-btn-landing-page, .primary-btn-landing-page--out-line, .disable-primary-btn-landing-page, .disable-primary-btn-landing-page--out-line {
      background: #19CBAC;
      padding: 6px 32px;
      width: auto;
      color: #FFFFFFFF;
      border: 1px solid #19CBAC;
      border-radius: 20px;

      &:hover {
        filter: brightness(95%);
        transition: all ease-out 0.2s;
      }
    }

    .disable-primary-btn-landing-page, .disable-primary-btn-landing-page--out-line {
      background: #BDBDBD !important;
      border-color: transparent !important;
      cursor: not-allowed;
      opacity: 0.65;

      &:hover {
        filter: initial;
        transition: all ease-out 0.2s;
      }
    }

    .primary-btn-landing-page--out-line {
      background: #FFFFFFFF;
      color: #19CBAC;

      &:hover {
        background: #19CBAC !important;
        border-color: #19CBAC !important;
        color: #FFFFFFFF;
        filter: brightness(110%);
        transition: all ease-out 0.2s;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;

      .primary-btn-landing-page, .primary-btn-landing-page--out-line {
        width: 100%;
      }
    }
  }

  .text-primary--landing-page {
    color: #19CBACFF;
  }

  .landing-text {
    color: #525252 !important;
  }

  .logo-landing-page {
    display: flex;
    justify-content: center;
  }

  .label-for-input {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 0;
  }

  .card-body {
    padding: 0;
  }

  .main-container {
    padding: 42px 4%;
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;

    @media (max-width: 768px) {
      padding: 24px 4%;
    }

    @media (max-width: 576px) {
      padding: 24px 4%;
    }
  }

}
</style>