<template>
  <div v-if="sitekey && local">
    <vue-recaptcha
        :sitekey="sitekey"
        @verify="onVerify"
        @expired="onExpired"
        :language="locale"
    />
  </div>
  <div v-else-if="BrandSetting?.recaptcha_information?.for_registration" align="left">
    <vue-recaptcha
        :sitekey="BrandSetting?.recaptcha_information?.site_key"
        @verify="onVerify"
        @expired="onExpired"
        :language="locale"
    />
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha'

export default {
  name: 'recaptchaComponent',
  props: {
    local: {
      type: Boolean,
      request: false,
      default: false
    },
    resetToken: {
      type: Boolean,
      request: false,
      default: false
    }
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      locale: this.$i18n.locale,
    }
  },
  computed: {
    BrandSetting() {
      return this.$store.getters["profile/getBandSettingMedia"] || JSON.parse(localStorage.getItem('authentication'))
    },
  },
  created() {
    if (!this.sitekey && this.locale) {
      this.$emit('verify')
    } else if (!this.BrandSetting?.recaptcha_information?.for_registration) {
      this.$emit('verify')
    }
  },
  methods: {
    onExpired() {
      this.$emit('verify', false)
      this.$emit('token', null)
    },
    onVerify(e) {
      this.$emit('token', e)
      this.$emit('verify', true)
    },
    resetVerify() {
      if (this.local) {
        if (!this.sitekey) {
          this.$emit('token', null)
          grecaptcha.reset()
        } else {
          this.$emit('token', '')
        }
        this.$emit('verify', false)
      } else {
        if (this.BrandSetting?.recaptcha_information?.for_registration) {
          this.$emit('token', null)
          grecaptcha.reset()
        } else {
          this.$emit('token', '')
        }
        this.$emit('verify', false)
      }

    }
  },
  watch: {
    resetToken(newVal) {
      if (newVal) {
        this.resetVerify()
      }
    }
  }
}

</script>


