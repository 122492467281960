<template>
  <div id="landing-page-otp-component">
    <b-card class="main-container mt-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-bold text-primary--landing-page">{{ $t('account.Confirmation') }}</h4>
        <language landing-page/>
      </div>
      <div>
        <b-row class="mt-2">
          <b-col sm="12" md="12" lg="12">
            <b-form-radio-group style="margin-top: 6px;" class="d-flex justify-content-between radio-type-otp-option"
                                v-model="form.otp" :disabled="counter !== null">
              <b-form-radio v-for="val in typeOTPOption" :key="val.id" :value="val.value" class="w-100">
                {{ $t(val.name) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-5 adjust-layout">
            <input-component
                landing-page
                v-model="sendValueOTP"
                disabled-type
                :label-input="form.otp === 'phone' ? $t('subscription.checkout.phone'): $t('subscription.checkout.email')"
            />
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-3 recaptcha-container" v-if="show_recaptcha">
            <recaptcha-component
                @verify="onVerify"
                :resetToken="resetToken"
                @token="getTokenReCap($event, 'otp')"
            ></recaptcha-component>
          </b-col>
          <b-col sm="12" md="12" lg="12" v-if="sendOTP" class="mt-3">
            <otp-input
                ref="otp_input_landing_page"
                id="otp-input-landing-page"
                :input-classes="inCorrectOTP ?  'otp-input-register-error':  'otp-input-register'"
                separator=" "
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-type="number"
                @on-change="onChangedHandlerOTP"
                @on-complete="verifyOTP"
            />
            <p class="d-flex justify-content-center m-0 mt-3 text-danger text-center" v-if="inCorrectOTP">
              {{ !!otpError ? otpError : $t('common.OTPDoesNotMatch') }}
            </p>
            <p class="d-flex justify-content-center landing-text m-0 mt-3 text-center">
              {{ otpDetail }}
            </p>
            <p class="d-flex justify-content-center text-primary--landing-page mt-0 text-center"
               style="white-space: nowrap">
              {{ refCode }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" class="mt-4">
            <div class="d-flex justify-content-between align-items-center btn-layout">
              <button class="primary-btn-landing-page--out-line" @click="backToReg">{{
                  $t('common.previous')
                }}
              </button>
              <button v-if="!sendOTP" class="primary-btn-landing-page"
                      @click="requestForOTP()"
                      :disabled="checkRecaptchaAvailable"
                      :class="{'disable-primary-btn-landing-page':checkRecaptchaAvailable}"
              >
                {{ $t('sell_landing_page.receive_otp') }}
                <b-spinner small class="ml-2" v-show="loading_req_otp"/>
              </button>
              <button v-if="sendOTP" class="primary-btn-landing-page"
                      :class="{'disable-primary-btn-landing-page':disableButton}" @click="requestOTP"
                      :disabled="disableButton"
              >
                <span>{{ $t('account.verify_otp.new_req_otp') }}</span>
                <span class="ml-1" v-if="counter">{{ counter }}</span>
                <b-spinner small class="ml-2" v-show="loading_req_otp"/>
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import masterData from "@/common/masterData.json";
import TopicComponent from "@/components/common/topicComponent.vue";
import RadioComponent from "@/components/common/radioComponent.vue";
import Language from "@/components/common/Language.vue";
import masterCountryCode from "@/common/masterCountryCode.json";
import OtpInput from "@bachdgvn/vue-otp-input";
import moment from "moment";
import CryptoJS from 'crypto-js';
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
// import recaptchaComponent from "@/components/common/recaptchaComponent.vue";
import recaptchaComponent from "@/views/sellPage/Register/recaptcha-component.vue";
import {mapState} from "vuex";
import settingApi from "@/repository/settingApi";

export default {
  name: "OtpRegister",
  components: {
    recaptchaComponent,
    Language,
    RadioComponent,
    TopicComponent,
    SelectComponent,
    InputComponent,
    OtpInput
  },
  data() {
    return {
      country_code_list: masterCountryCode.country_code,
      sendOTP: false,
      form: {
        otp: "phone",
      },
      cookie: null,
      resetToken: false,
      password: null,
      loading_req_otp: false,
      ref_code: '',
      sendValueOTP: null,
      timeCountdown: null,
      inCorrectOTP: false,
      otpError: '',
      transaction_id: null,
      counter: null,
      recaptcha_token: sessionStorage.getItem('recaptcha_token') ? sessionStorage.getItem('recaptcha_token') : null,
      rateTime: 3,
      recaptchaVerify: false,
      show_recaptcha: true,
      dateCountDown: moment(3, 'minutes'),
      typeList: masterData.typeList,
      juristic_type_list: masterData.juristic_list,
      package_id: null,
      disable_button: true,
      typeOTPOption: [
        {
          value: 'phone',
          name: 'account.verify_otp.receive_otp_phone'
        },
        {
          value: 'email',
          name: 'account.verify_otp.receive_otp_email'
        }
      ]
    }
  },
  methods: {
    getBrandSettingOtpType() {
      let valuesToRemove = []
      settingApi.getBrandSetting().then(resp => {
        const notification_type = resp?.notification
        const verify_phone = notification_type?.sms?.otp?.verify_phone_number
        const verify_email = notification_type?.email?.customer_notification?.verify_email
        if (!verify_phone && verify_email) {
          valuesToRemove = ['phone']
          this.form.otp = 'email'
          this.sendValueOTP = this.tempRegister?.email
        } else if (verify_phone && !verify_email) {
          valuesToRemove = ['email']
          this.form.otp = 'phone'
          this.sendValueOTP = this.tempRegister?.phone
        }
        this.typeOTPOption = this.typeOTPOption.filter(item => !valuesToRemove.includes(item.value))
      }).catch((err) => {
        throw err
      }).finally(() => {
      })
    },
    getTokenReCap(e, key) {
      if (key === 'otp') {
        this.recaptcha_token = e
      }
    },
    onVerify(e) {
      this.recaptchaVerify = e
    },
    backToReg() {
      this.recaptcha_token = null
      this.setPhase('registerPhase')
    },
    requestForOTP() {
      const verify = this.recaptcha ? (this.recaptchaVerify || !_.isNil(this.recaptcha_token)) : true
      if (verify) {
        this.requestOTP()
      }
    },
    verifyOTP(otp) {
      const data = {
        token: this.transaction_id,
        tax_number: this.tempRegister?.taxID,
        otp_code: otp,
        type: this.form.otp,
        ref_code: this.ref_code,
        email: this.tempRegister.email,
        password: this.password,
        first_name: this.tempRegister?.companyName,
        last_name: this.tempRegister?.lastName,
        phone: this.tempRegister?.phone,
        is_juristic: this.tempRegister?.juristic_type === 'juristic',
        include_withholding_tax: false,
        address: {
          address: this.tempRegister?.address,
          sub_district: this.tempRegister?.sub_district,
          district: this.tempRegister?.district,
          province: this.tempRegister?.province,
          zipcode: this.tempRegister?.zipCode,
          country: this.tempRegister?.country
        },
        which_channel_from: null,
        which_service_from: null,
        tracking_channel: this.cookie,
        sales: this.tempRegister?.sales
      }
      profileApi.verifyOTP(data).then(response => {
        sessionStorage.setItem('juristic_type', this.tempRegister.juristic_type)
        this.$store.dispatch('profile/setUserInfo', response)
        const profile = this.$store.getters["profile/getUserInfo"]
        this.$store.dispatch('profile/setName', profile?.first_name + " " + profile?.last_name)
        this.$store.dispatch('profile/setCredit', profile.sms_unit)
        this.$store.dispatch('profile/setURLTracking', profile.url_tracking)
        this.$i18n.locale = profile.locale
        alert.addNewSuccess(this)
        this.package_id = sessionStorage.getItem('package_id') ? sessionStorage.getItem('package_id') : null
        this.inCorrectOTP = false
        setTimeout(() => {
              if (!_.isNil(this.package_id) || !_.isEmpty(this.package_id)) {
                this.$router.push(`/Sell/Subscriptions?package_id=${this.package_id}`).catch(() => {
                })
              } else {
                this.$router.push('/Dashboard').catch(() => {
                })
              }
              sessionStorage.removeItem('token_register')
              sessionStorage.removeItem('package_id')
              sessionStorage.removeItem('setPhase')
              sessionStorage.removeItem('setLandingPageRegister')
              sessionStorage.removeItem('premium_url')
            }
            , 1200)
      }).catch(err => {
        if (err) {
          this.inCorrectOTP = true
          let code = err?.response?.data?.errors?.code
          if (code === 436 || code === '436') {
            this.otpError = this.$t('common.OTPDoesNotMatch')
          } else if (code === 1018 || code === '1018') {
            this.otpError = this.$t('common.OTPExpiry')
          } else {
            this.otpError = err?.response?.data?.message
          }
        }
      }).finally(() => {
        // this.$refs?.otp_input_landing_page.clearInput();
      })
    },
    requestOTP() {
      this.disable_button = true
      this.loading_req_otp = true
      this.sendOTP = true
      const data = {
        phone: this.tempRegister.phone,
        email: this.tempRegister.email,
        type: this.form.otp,
        recaptcha_token: this.recaptcha_token,
      }
      profileApi.otpRegisterSend(data).then(response => {
        if (response?.codeSYS === '001') {
          this.show_recaptcha = false
          this.ref_code = response.ref_code
          this.transaction_id = response.transaction_id
          this.rateTime = response?.validity
          this.dateCountDown = moment(response?.validity ? response?.validity : 3, 'minutes')
          this.setTime()
        }
      }).catch(err => {
        this.sendOTP = false
        if (err?.response?.status !== 429) {
          this.$swal.fire({
            icon: 'error',
            title: this.$t('common.addNewFailed'),
            html: '<p>' + this.$t('common.somethingWrong') + '</p>' + err.response.data.message,
            confirmButtonColor: '#3085d6',
          })
        }
      }).finally(() => {
        this.loading_req_otp = false
        this.$refs?.otp_input_landing_page.clearInput();
      })
    },
    clearSetTime() {
      this.show_recaptcha = true
      this.recaptcha_token = null
      this.counter = null
      this.recaptchaVerify = false
      this.disable_button = false
      this.resetToken = true
      clearInterval(this.timeCountdown)
      this.dateCountDown = moment(3, 'minutes')
    },
    setTime() {
      this.timeCountdown = setInterval(() => {
        this.dateCountDown = moment(this.dateCountDown.subtract(1, 'seconds'))
        this.counter = this.dateCountDown.format('mm:ss')
        if (this.counter === '00:00') {
          this.clearSetTime()
        }
      }, 1000);
    },
    verifyHasAllData() {
      const password = sessionStorage.getItem('token_register') ? sessionStorage.getItem('token_register') : null
      if (!_.isNil(this.tempRegister) && !_.isNil(password)) {
        const verify = !!this.tempRegister?.taxID
            && !!this.tempRegister?.companyName
            && !!this.tempRegister?.address
            && !!this.tempRegister?.phone
            && !!this.tempRegister?.email
            && !!this.tempRegister?.country
            && !!this.tempRegister?.juristic_type
            && !!this.tempRegister?.sub_district
            && !!this.tempRegister?.district
            && !!this.tempRegister?.province
            && !!this.tempRegister?.zipCode
            && !!password
            && this.tempRegister?.approve
        return this.tempRegister?.juristic_type === 'juristic' ? verify : verify && !!this.tempRegister?.lastName
      }
      return false
    },
    setOTPOption(otpType) {
      const phone = this.tempRegister?.phone ? this.tempRegister?.phone : null
      const email = this.tempRegister?.email ? this.tempRegister?.email : null
      this.sendValueOTP = otpType === 'phone' ? phone : email
    },
    redirectReg() {
      if (!this.verifyHasAllData()) {
        this.$emit('submitPhase', 'registerPhase')
      }
    },
    setPhase(param) {
      this.$emit('submitPhase', param)
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
    onChangedHandlerOTP(event) {
      if (event.length < 6) {
        this.inCorrectOTP = false
        this.otpError = ''
      }
    }
  },
  computed: {
    ...mapState({
      recaptcha: state => state.profile?.bandSettingMedia?.recaptcha_information?.for_registration,
    }),
    checkRecaptchaAvailable() {
      return this.recaptcha ? (!this.recaptchaVerify || _.isNil(this.recaptcha_token)) : false
    },
    tempRegister() {
      const tempRegister = sessionStorage.getItem('setLandingPageRegister') ? sessionStorage.getItem('setLandingPageRegister') : null
      return !_.isNil(tempRegister) ? JSON.parse(tempRegister) : null
    },
    refCode() {
      return `Ref Code : ${this.ref_code} ( ${this.$t('account.verify_otp.exp_code')}  ${this.rateTime}  ${this.$t('common.minute')} )`
    },
    otpDetail() {
      return `${this.$t('account.verify_otp.enter_otp_code')} ${this.form.otp === 'phone' ? this.$t('SMS') : this.$t('EMAIL')} ${this.form.otp === 'phone' ? this.$t('account.verify_otp.verify_identity') :
          this.$t('account.verify_otp.verify_identity_email')} `
    },
    disableButton() {
      return this.disable_button || this.checkRecaptchaAvailable
    },
  },
  beforeMount() {
    this.redirectReg()
  },
  beforeDestroy() {
    this.clearSetTime()
  },
  mounted() {
    const password = sessionStorage.getItem('token_register') ? sessionStorage.getItem('token_register') : null
    if (!_.isNil(password)) {
      this.password = CryptoJS.AES.decrypt(
          password,
          'secret_password#'
      ).toString(CryptoJS.enc.Utf8)
    }
    this.cookie = this.getCookie('TRACKING_CHANNEL') ? this.getCookie('TRACKING_CHANNEL') : null
    this.setOTPOption(this.form.otp)
    this.getBrandSettingOtpType()
  },
  watch: {
    'form.otp'(val) {
      if (val) {
        this.sendOTP = false
        this.$refs?.otp_input_landing_page?.clearInput();
        this.setOTPOption(val)
      }
    }
  }
}
</script>
<style lang="scss">
#landing-page-otp-component {
  .radio-type-otp-option {
    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 8px;
    }
  }

  .recaptcha-container {
    display: flex;
    justify-content: flex-start;
    @media (max-width: 768px) {
      overflow-x: scroll;
    }
  }

  .adjust-layout {
    @media (max-width: 768px) {
      margin-bottom: 0px !important;
    }
  }

  .custom-control-input:disabled ~ .custom-control-label, .custom-control-input[disabled] ~ .custom-control-label {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .custom-control-input:disabled ~ .custom-control-label:after {
    cursor: not-allowed;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #19CBACFF !important;
    background-color: #19CBACFF !important;
  }

  .custom-control-label {
    font-weight: 400;
    font-size: 15px;
    color: #525252 !important;
  }
}

#otp-input-landing-page {
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 8px;
  }

  .otp-input-register::-webkit-inner-spin-button,
  .otp-input-register::-webkit-outer-spin-button,
  .otp-input-register-error::-webkit-inner-spin-button,
  .otp-input-register-error::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  .otp-input-register, .otp-input-register-error {
    width: 5.5rem;
    height: 5.5rem;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #E9E9E9;
    font-size: 36px;

    &.error {
      border: 1px solid red !important;
    }

    @media (max-width: 576px) {
      width: 3rem;
      height: 3rem;
      border-radius: 10px;
      font-size: 25px;
    }
  }

  .otp-input-register-error {
    border: 1px solid #E9E9E9;
    background: #FFE9E9;
    color: red;
  }
}

</style>