<template>
  <div id="landing-page-set-password-component">
    <b-card class="main-container mt-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-bold text-primary--landing-page">{{ $t('sell_landing_page.setPassword') }}</h4>
        <language landing-page/>
      </div>
      <div>
        <b-row class="mt-2">
          <b-col sm="12" md="12" lg="12">
            <input-component
                :label-input="$t('account.from.password')"
                v-model="$v.form.password.$model"
                landing-page
                required
                type="password"
                icon="password"
                :require-label="errorPassword"
                :state="validateState('password')"
            />
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-4">
            <input-component
                :label-input="$t('account.from.confirmPassword')"
                v-model="$v.form.confirm_password.$model"
                landing-page
                required
                type="password"
                icon="password"
                :require-label="errorConfirmPassword"
                :state="validateState('confirm_password')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" class="mt-4">
            <div class="d-flex justify-content-between align-items-center btn-layout">
              <button class="primary-btn-landing-page--out-line" @click="setPhase('registerPhase')">
                {{ $t('common.previous') }}
              </button>
              <button class="primary-btn-landing-page"
                      :disabled="$v.form.$invalid"
                      :class="{'disable-primary-btn-landing-page':$v.form.$invalid }"
                      @click="setPhase('otpPhase')">{{ $t('common.next') }}
                <b-spinner small class="ml-2" v-show="false"/>
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import TopicComponent from "@/components/common/topicComponent.vue";
import RadioComponent from "@/components/common/radioComponent.vue";
import Language from "@/components/common/Language.vue";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import CryptoJS from 'crypto-js';

export default {
  name: "setPasswordRegister",
  components: {
    Language,
    RadioComponent,
    TopicComponent,
    SelectComponent,
    InputComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        password: '',
        confirm_password: ''
      }
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('password'),
      }
    },
  },
  methods: {
    validateState(validation) {
      const {$dirty, $error} = this.$v.form[validation];
      return $dirty ? !$error : null;
    },
    verifyHasAllData() {
      if (!_.isNil(this.tempRegister)) {
        const verify = !!this.tempRegister?.taxID
            && !!this.tempRegister?.companyName
            && !!this.tempRegister?.phone
            && !!this.tempRegister?.lastName
            && !!this.tempRegister?.email
            && !!this.tempRegister?.country
            && !!this.tempRegister?.juristic_type
            && !!this.tempRegister?.sub_district
            && !!this.tempRegister?.district
            && !!this.tempRegister?.province
            && !!this.tempRegister?.zipCode
            && this.tempRegister?.approve
        return verify
      }
      return false
    },
    redirectReg() {
      if (!this.verifyHasAllData()) {
        this.$emit('submitPhase', 'registerPhase')
      }
    },
    setPhase(param) {
      if (!this.$v.form.$invalid && param === 'otpPhase') {
        const hash_password = CryptoJS.AES.encrypt(this.form.password, 'secret_password#').toString()
        sessionStorage.setItem('password', hash_password)
        this.$emit('submitPhase', param)
      } else {
        this.$emit('submitPhase', param)
      }
    },
  },
  computed: {
    errorConfirmPassword() {
      let errors = []
      if (!_.isNil(this.validateState('confirm_password'))) {
        if (!this.$v.form.confirm_password.$dirty) return errors
        !this.$v.form.confirm_password.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.confirm_password.minLength && errors.push('กรุณากรอกตัวเลขหรือตัวอักษรขั้นต่ำ 6 ตัว')
        !this.$v.form.confirm_password.sameAsPassword && errors.push(this.$t('common.notSameAsPassword'))
        return errors[0]
      }
      return errors[0]
    },
    errorPassword() {
      let errors = []
      if (!_.isNil(this.validateState('password'))) {
        if (!this.$v.form.password.$dirty) return errors
        !this.$v.form.password.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.password.minLength && errors.push('กรุณากรอกตัวเลขหรือตัวอักษรขั้นต่ำ 6 ตัว')
        return errors[0]
      }
      return errors[0]
    },
    tempRegister() {
      const tempRegister = sessionStorage.getItem('setLandingPageRegister') ? sessionStorage.getItem('setLandingPageRegister') : null
      return !_.isNil(tempRegister) ? JSON.parse(tempRegister) : null
    }
  },
  beforeMount() {
    sessionStorage.removeItem('password')
    this.redirectReg()
  }
}
</script>
<style lang="scss">
#landing-page-set-password-component {
}
</style>