<template>
  <div id="landing-page-register-component">
    <b-card class="main-container mt-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-bold text-primary--landing-page">{{ $t('sell_landing_page.recommend') }}</h4>
        <language landing-page/>
      </div>
      <div>
        <b-row class="mt-2">
          <b-col sm="12" md="12" lg="6">
            <radio-component :custom-style="customStyle" :require-label="$t('setting.senderID.form.juristic_type')"
                             required
                             v-model="form.juristic_type"
                             landing-page
                             :option="typeCustomerOption"
                             :key="form.juristic_type"
            ></radio-component>
          </b-col>
          <b-col sm="12" md="12" lg="6">
            <input-component
                landing-page
                v-model="$v.form.taxID.$model"
                @keypress="chkNumber"
                max="13"
                :label-input="taxAndCardID"
                :require-label="errorTaxID"
                :state="validateState('taxID')"
                required
            />
          </b-col>
          <b-col sm="12" md="12" :lg="form.juristic_type === 'personal'? 6:12" class="mt-3">
            <input-component
                landing-page
                v-model="$v.form.companyName.$model"
                :label-input="companyAndFirstName"
                :max="maxCompanyName"
                :require-label="errorCompanyName"
                @keypress="checkIsOnlyTHEnglish"
                :onlyTHEnglish="true"
                :state="validateState('companyName')"
                required
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6" v-if="form.juristic_type === 'personal'" class="mt-3">
            <input-component :label-input="$t('subscription.checkout.last_name')"
                             landing-page
                             required
                             max="50"
                             @keypress="checkIsOnlyTHEnglish"
                             :onlyTHEnglish="true"
                             :require-label="errorLastName"
                             v-model="$v.form.lastName.$model"
                             :state="validateState('lastName')"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6" class="mt-3">
            <input-component :label-input="$t('subscription.checkout.phone')"
                             required
                             max="10"
                             landing-page
                             v-model="$v.form.phone.$model"
                             @keypress="chkNumber"
                             :require-detail="dupPhone"
                             @input="handleSearchInput"
                             :require-label="errorPhone"
                             :state="validateState('phone') && telState && dupPhone === ''"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6" class="mt-3">
            <input-component :label-input="$t('subscription.checkout.email')"
                             required
                             landing-page
                             :require-detail="dupEmail?$t('common.emailDup'):''"
                             @focusout="checkEmailDup"
                             v-model="$v.form.email.$model"
                             :require-label="errorEmail"
                             :state="validateState('email') && !dupEmail"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" class="mt-3">
            <input-component
                :label-input="$t('account.from.password')"
                v-model="$v.password_set.actual_password.$model"
                landing-page
                required
                type="password"
                icon="password"
                :require-label="errorPassword"
                :state="validatePasswordState('actual_password')"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" class="mt-3">
            <input-component
                :label-input="$t('account.from.confirmPassword')"
                v-model="$v.password_set.confirm_password.$model"
                landing-page
                required
                type="password"
                icon="password"
                :require-label="errorConfirmPassword"
                :state="validatePasswordState('confirm_password')"
            />
          </b-col>
        </b-row>
        <h4 class="text-bold text-primary--landing-page mt-4">{{ $t('sell_landing_page.receipt') }}</h4>
        <b-row class="mt-2" style="position: relative; z-index: 2">
          <b-col sm="12" md="12" lg="6" class="mt-3">
            <select-component :options="country_code_list" :label-select="$t('subscription.checkout.country')"
                              required
                              landingPage
                              v-model="$v.form.country.$model"
                              text-field="country"
                              :require-label="errorCountry"
                              :state="validateState('country')"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6" class="mt-3">
            <input-component
                landing-page
                v-model="$v.form.address.$model"
                :label-input="$t('subscription.checkout.address')"
                :require-label="errorAddress"
                :state="validateState('address')"
                required
            />
          </b-col>
          <b-col sm="12" md="12" lg="6"
                 :class="{'mt-2':$v.form.address.$error && innerWidth>mobileWidth,'mt-4':innerWidth<=mobileWidth}"
                 :style="{marginBottom :($v.form.sub_district.$error)&& innerWidth<=mobileWidth ? '24px !important': ''}"
          >
            <address-component :error="$v.form.sub_district.$error" :label=" $t('subscription.checkout.sub_district')"
                               required>
              <addressinput-subdistrict label="" v-model="$v.form.sub_district.$model"
                                        :id="$v.form.sub_district.$error ? 'sub_district-register-landing-page': '' "
              />
            </address-component>
          </b-col>
          <b-col sm="12" md="12" lg="6"
                 :class="{'mt-2':$v.form.address.$error && innerWidth>mobileWidth,'mt-4':innerWidth<=mobileWidth}"
                 :style="{marginBottom :($v.form.district.$error)&& innerWidth<=mobileWidth ? '24px !important': ''}"
          >
            <address-component :error="$v.form.district.$error" :label=" $t('subscription.checkout.district')" required>
              <addressinput-district label="" v-model="$v.form.district.$model"
                                     :id="$v.form.district.$error ? 'district-register-landing-page': '' "
              />
            </address-component>
          </b-col>
          <b-col sm="12" md="12" lg="6"
                 class="custom-margin-province-zipcode"
                 :class="{'error-custom-margin-province-zipcode':provinceZipCodeMargin && innerWidth>mobileWidth,'mt-4':innerWidth<=mobileWidth}"
                 :style="{marginBottom :($v.form.province.$error)&& innerWidth<=mobileWidth ? '24px !important': ''}"
          >
            <address-component :error="$v.form.province.$error" :label=" $t('subscription.checkout.province')" required>
              <addressinput-province label="" v-model="$v.form.province.$model"
                                     :id="$v.form.province.$error ? 'province-register-landing-page': '' "
              />
            </address-component>
          </b-col>
          <b-col sm="12" md="12" lg="6"
                 class="custom-margin-province-zipcode"
                 :class="{'error-custom-margin-province-zipcode':provinceZipCodeMargin && innerWidth>mobileWidth,'mt-4':innerWidth<=mobileWidth}"
                 :style="{marginBottom :($v.form.zipCode.$error)&& innerWidth<=mobileWidth ? '24px !important': ''}"
          >

            <address-component :error="$v.form.zipCode.$error" :label=" $t('subscription.checkout.zip_code')" required>
              <addressinput-zipcode label="" v-model="$v.form.zipCode.$model"
                                    :id="$v.form.zipCode.$error ? 'zipcode-register-landing-page': '' "
              />
            </address-component>
          </b-col>
        </b-row>
        <div class="mt-3">
          <select-component :options="sales_list" :label-select="$t('common.sales_lead')"
                            landingPage
                            v-model="form.sales"
                            text-field="value"
          />
        </div>
        <b-row
            :class="{'mt-3': ($v.form.zipCode.$error||$v.form.province.$error) && innerWidth>mobileWidth}">
          <b-col sm="12" md="12" lg="12" class="mt-3">
            <b-checkbox v-model="form.approve">
              <label class="landing-text-grey">
                {{ agreement }}
              </label>
            </b-checkbox>
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-4">
            <div class="d-flex justify-content-between align-items-center btn-layout">
              <button class="primary-btn-landing-page--out-line" @click="packageList">{{
                  $t('common.previous')
                }}
              </button>
              <button class="primary-btn-landing-page"
                      :disabled="checkUnCompleteForm || dupEmail || dupPhone !== ''"
                      :class="{'disable-primary-btn-landing-page':checkUnCompleteForm  || dupEmail || dupPhone !== ''}"
                      @click="submitRegister('otpPhase')">{{ $t('common.next') }}
                <b-spinner small class="ml-2" v-show="false"/>
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import addressComponent from "@/components/common/addressComponent.vue";
import InputComponent from "@/components/common/inputComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import masterData from "@/common/masterData.json";
import TopicComponent from "@/components/common/topicComponent.vue";
import RadioComponent from "@/components/common/radioComponent.vue";
import Language from "@/components/common/Language.vue";
import masterCountryCode from "@/common/masterCountryCode.json";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required, requiredIf, email, sameAs} from "vuelidate/lib/validators";
import {mapState} from "vuex";
import functionsCommon from "@/common/functions";
import recaptchaComponent from "@/views/sellPage/Register/recaptcha-component.vue";
import CryptoJS from "crypto-js";
import profileApi from "@/repository/profileApi";

export default {
  name: "SellRegister",
  components: {
    recaptchaComponent,
    Language,
    addressComponent,
    RadioComponent,
    TopicComponent,
    SelectComponent,
    InputComponent,
  },
  data() {
    return {
      country_code_list: masterCountryCode.country_code,
      typeCustomerOption: [
        {
          value: 'personal',
          name: 'multipleCreateSender.juristicTypeList.personal'
        },
        {
          value: 'juristic',
          name: 'multipleCreateSender.juristicTypeList.juristic'
        }
      ],
      password_set: {
        actual_password: '',
        confirm_password: ''
      },
      dupEmail: false,
      form: {
        address: '',
        taxID: '',
        companyName: '',
        phone: '',
        lastName: '',
        email: '',
        country: 'th',
        juristic_type: "personal",
        sub_district: '',
        district: '',
        province: '',
        zipCode: '',
        approve: false,
        include_withholding_tax: false,
        sales: null
      },
      typeList: masterData.typeList,
      juristic_type_list: masterData.juristic_list,
      sales_list:[],
      searchDelayTimer: null,
      dupPhone: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    password_set: {
      actual_password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('actual_password'),
      }
    },
    form: {
      companyName: {required},
      lastName: {
        required: requiredIf(function () {
          return this.form.juristic_type === 'personal'
        })
      },
      phone: {required},
      taxID: {
        required: required,
        maxLength: maxLength(13),
        minLength: minLength(10)
      },
      address: {required},
      email: {required, email},
      country: {required},
      sub_district: {required},
      district: {required},
      province: {required},
      zipCode: {required},
    },
  },
  beforeMount() {
    sessionStorage.removeItem('recaptcha_token')
    sessionStorage.removeItem('token_register')
    sessionStorage.removeItem('_order_sell_page')
    sessionStorage.removeItem('is_payment_sell_page')
    sessionStorage.removeItem('paymentDetailPhase')
    this.password_set.actual_password = ''
    this.password_set.confirm_password = ''
  },
  mounted() {
    const tempRegister = sessionStorage.getItem('setLandingPageRegister') ? sessionStorage.getItem('setLandingPageRegister') : null
    const form = !_.isNil(tempRegister) ? JSON.parse(tempRegister) : null
    this.form = !_.isNil(form) ? form : this.form
    this.form.juristic_type = form?.juristic_type ? form?.juristic_type : "personal"
    this.getMasterSalesList()
  },
  methods: {
    checkEmailDup() {
      let lower = this.form.email.toLowerCase()
      const data = {
        type: "email",
        value: lower.trim()
      }
      profileApi.checkDuplicate(data).then((response) => {
        response.codeSYS === '001' && !response.success ? this.dupEmail = true : this.dupEmail = false
      })
    },
    packageList() {
      this.$router.push({path: '/Sell/Package-List'})
    },
    validateState(validation) {
      const {$dirty, $error} = this.$v.form[validation];
      return $dirty ? !$error : null;
    },
    validatePasswordState(validation) {
      const {$dirty, $error} = this.$v.password_set[validation];
      return $dirty ? !$error : null;
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    submitRegister(param) {
      if (!this.checkUnCompleteForm && !this.dupEmail) {
        let lower = this.form.email.toLowerCase()
        const data = {
          type: "email",
          value: lower.trim()
        }
        profileApi.checkDuplicate(data).then((response) => {
          response.codeSYS === '001' && !response.success ? this.dupEmail = true : this.dupEmail = false
          if (!this.dupEmail) {
            const hash_password = CryptoJS.AES.encrypt(this.password_set.actual_password, 'secret_password#').toString()
            sessionStorage.setItem('token_register', hash_password)
            sessionStorage.setItem('setLandingPageRegister', JSON.stringify(this.form))
            this.$emit('submitPhase', param)
          } else {
            this.form.email = ''
          }
        })
      }
    },
    getMasterSalesList () {
      profileApi.getMasterSalesList().then(resp => {
        const filter = _.filter(resp, item => {
          return item.is_active === true
        })
        this.sales_list = _.map(filter, item => {
          return {
            text: item?.name,
            value: item?.name
          }
        })
      }).catch(err => {
        throw err
      })
    },
    checkIsOnlyTHEnglish (e) {
      return functionsCommon.isOnlyTHEnglish(e)
    },
    handleSearchInput() {
      clearTimeout(this.searchDelayTimer);
      this.searchDelayTimer = setTimeout(() => {
        this.checkDuplicatePhone(this.form.phone)
      }, 100);
    },
    checkDuplicatePhone (phoneNumber) {
      const data = {
        type: "phone",
        value: phoneNumber
      }
      profileApi.checkDuplicatePhoneNumber(data).then(resp => {
        if (resp.codeSYS === '001' && !resp.success) {
          this.dupPhone = this.$t('common.phoneDup')
        } else {
          this.dupPhone = ""
        }
      }).catch(err => {
        throw err
      })
    },
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    }),
    provinceZipCodeMargin() {
      return this.$v.form.district.$error || this.$v.form.sub_district.$error
    },
    checkUnCompleteForm() {
      return !this.telState || this.$v.form.$invalid || this.$v.password_set.$invalid || !this.form.approve
    },
    agreement() {
      const th = `${this.$t('account.from.accept')}${this.$t('account.from.termOfService')}${this.$t('account.from.and')}${this.$t('account.from.policy')}${this.$t('account.from.already')}`
      return this.$i18n.locale === 'th' ? th : `${this.$t('account.from.accept')} ${this.$t('account.from.termOfService')} ${this.$t('account.from.and')} ${this.$t('account.from.policy')} ${this.$t('account.from.already')}`
    },
    telState() {
      return functionsCommon.telState(this.form.phone)
    },
    telInvalid() {
      const data = functionsCommon.telInvalid(this.form.phone)
      return this.$t(data)
    },
    errorAddress() {
      let errors = []
      if (!_.isNil(this.validateState('address'))) {
        if (!this.$v.form.address.$dirty) return errors
        !this.$v.form.address.required && errors.push(this.$t('common.requiredField'))
        return errors[0]
      }
      return errors[0]
    },
    errorConfirmPassword() {
      let errors = []
      if (!_.isNil(this.validatePasswordState('confirm_password'))) {
        if (!this.$v.password_set.confirm_password.$dirty) return errors
        !this.$v.password_set.confirm_password.required && errors.push(this.$t('common.requiredField'))
        !this.$v.password_set.confirm_password.minLength && errors.push(this.$t('sell_landing_page.minPassword'))
        !this.$v.password_set.confirm_password.sameAsPassword && errors.push(this.$t('common.notSameAsPassword'))
        return errors[0]
      }
      return errors[0]
    },
    errorPassword() {
      let errors = []
      if (!_.isNil(this.validatePasswordState('actual_password'))) {
        if (!this.$v.password_set.actual_password.$dirty) return errors
        !this.$v.password_set.actual_password.required && errors.push(this.$t('common.requiredField'))
        !this.$v.password_set.actual_password.minLength && errors.push(this.$t('sell_landing_page.minPassword'))
        return errors[0]
      }
      return errors[0]
    },
    errorTaxID() {
      let errors = []
      if (!_.isNil(this.validateState('taxID'))) {
        if (!this.$v.form.taxID.$dirty) return errors
        !this.$v.form.taxID.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.taxID.maxLength && errors.push(this.$t('sell_landing_page.idLength'))
        !this.$v.form.taxID.minLength && errors.push(this.$t('sell_landing_page.idLength'))
        return errors[0]
      }
      return errors[0]
    },
    errorPhone() {
      let errors = []
      if (!_.isNil(this.validateState('phone'))) {
        if (!this.$v.form.phone.$dirty) return errors
        !this.$v.form.phone.required && errors.push(this.$t('common.requiredField'))
        !this.telState && errors.push(this.telInvalid)
        return errors[0]
      }
      return errors[0]
    },
    errorEmail() {
      let errors = []
      if (!_.isNil(this.validateState('email'))) {
        if (!this.$v.form.email.$dirty) return errors
        !this.$v.form.email.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.email.email && errors.push(this.$t('common.email_format'))
        return errors[0]
      }
      return errors[0]
    },
    errorCountry() {
      let errors = []
      if (!_.isNil(this.validateState('country'))) {
        if (!this.$v.form.country.$dirty) return errors
        !this.$v.form.country.required && errors.push(this.$t('common.requiredField'))
        return errors[0]
      }
      return errors[0]
    },
    errorLastName() {
      let errors = []
      if (!_.isNil(this.validateState('lastName'))) {
        if (!this.$v.form.lastName.$dirty) return errors
        !this.$v.form.lastName.required && errors.push(this.$t('common.requiredField'))
        return errors[0]
      }
      return errors[0]
    },
    errorCompanyName() {
      let errors = []
      if (!_.isNil(this.validateState('companyName'))) {
        if (!this.$v.form.companyName.$dirty) return errors
        !this.$v.form.companyName.required && errors.push(this.$t('common.requiredField'))
        return errors[0]
      }
      return errors[0]
    },
    maxCompanyName() {
      return this.form.juristic_type == 'personal' ? '50' : ''
    },
    taxAndCardID() {
      return this.form.juristic_type === 'juristic' ? this.$t('subscription.checkout.tax_id') : this.$t('subscription.checkout.card_id')
    },
    companyAndFirstName() {
      return this.form.juristic_type === 'juristic' ? this.$t('subscription.checkout.company_name') : this.$t('subscription.checkout.first_name')
    },
    customStyle() {
      return {
        gap: '80px'
      }
    }
  },
}
</script>
<style>
#landing-page-register-component {
  .custom-margin-province-zipcode {
    margin-top: 18px;
  }

  .error-custom-margin-province-zipcode {
    margin-top: 36px;
  }

  .recaptcha-container {
    @media (max-width: 768px) {
      overflow-x: scroll;
    }
  }

  input#zipcode-register-landing-page, input#sub_district-register-landing-page, input#province-register-landing-page, input#district-register-landing-page {
    padding-right: 12px !important;
    border: 1px solid var(--danger) !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #19CBACFF !important;
    background-color: #19CBACFF !important;
  }
}
</style>